@media screen and (max-width: 767px) {
  .Title {
    font-size: large;
    margin-top: 5%;
  }
}

@media screen and (min-width: 768px) {
  .Title {
    margin-top: 5%;
  }
}
