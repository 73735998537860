.Toolbar {
  height: 64px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #fff;
  border-bottom: 1px solid lightgrey;
}

.Toolbar nav {
  height: 100%;
}
