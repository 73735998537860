@media screen and (max-width: 767px) {
  .UC_Logo {
    width: 70%;
  }

  .CSRM_Logo {
    width: 125%;
    padding-left: 10%;
  }

  .MainTitle {
    font-size: 0.7em;
    font-weight: bold;
  }

  .SubTitle {
    font-size: 0.6em;
    padding: 2%;
  }

  .AboutLink {
    font-size: x-small;
    text-decoration: underline;
  }

  .DisableText label {
    height: 65px !important;
  }

  .DisableRadioText {
    height: 25px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .UC_Logo {
    width: 70%;
  }

  .CSRM_Logo {
    width: 100%;
  }

  .MainTitle {
    font-size: medium;
    font-weight: bold;
  }

  .SubTitle {
    font-size: xx-small;
  }

  .AboutLink {
    font-size: small;
    text-decoration: underline;
  }
}

@media screen and (min-width: 992px) {
  .UC_Logo {
    width: 45%;
  }

  .CSRM_Logo {
    width: 70%;
  }

  .MainTitle {
    font-size: 1.5em;
    font-weight: bold;
  }

  .SubTitle {
    font-size: medium;
  }

  .AboutLink {
    font-size: medium;
    text-decoration: underline;
  }
}
