@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.odd-row {
  background: #ddebf7;
}

.even-row {
  background: #fff;
}

/*tool bar*/
.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-menu-horizontal > .ant-menu-item > a:hover {
  border: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-item {
  border: none !important;
}

.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-item-active > a,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-item-open > a,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item-selected > a {
  border-bottom: none !important;
  font-size: 15px !important;
}

.ant-menu-light .ant-menu-submenu-title:hover {
  border-bottom: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after {
  border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-item::after {
  border-bottom: none !important;
  transition: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: none !important;
}

/* @media screen and (max-width: 768px) {
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
    height: 55px !important;
  }
} */
